(function($){
    "use strict";

    // Navbar Menu JS
    $('.navbar .navbar-nav li a.scroll-link').on('click', function(e){
        let anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $(anchor.attr('href')).offset().top - 45
        }, 1500);
        e.preventDefault();

    });

    //Spec Menu JS
    // Navbar Menu JS
    $('#spec-navbar a.scroll-link').on('click', function(e){
        let anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $(anchor.attr('href')).offset().top - 45
        }, 1500);
        e.preventDefault();

    });

    $('.navbar .navbar-nav li a').on('click', function(){
        $('.navbar-collapse').collapse('hide');
    });

    // Header Sticky
    $(window).on('scroll',function() {
        if ($(this).scrollTop() > 120){
            $('.navbar').addClass("is-sticky");
        }
        else{
            $('.navbar').removeClass("is-sticky");
        }
    });

    // Popup Image
    $('.popup-btn').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    // Feedback Slides
    $('.feedback-slides').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        margin: 30,
        autoplayHoverPause: true,
        autoplay: true,
        navText: [
            "<i class='flaticon-left-arrow'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    });

    // Tabs
    (function ($) {
        $('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
        $('.tab ul.tabs li a').on('click', function (g) {
            var tab = $(this).closest('.tab'),
            index = $(this).closest('li').index();
            tab.find('ul.tabs > li').removeClass('current');
            $(this).closest('li').addClass('current');
            tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
            tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
            g.preventDefault();
        });
    })(jQuery);

    // Popup Video
    $('.popup-youtube').magnificPopup({
        disableOn: 320,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    // Specifications Image Slides
    $('.specifications-image-slides').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        autoplay: true,
        items: 1,
        navText: [
            "<i class='flaticon-back'></i>",
            "<i class='flaticon-back'></i>"
        ],
    });

    // Photo Gallery Slides
    $('.photo-gallery-slides').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        navText: [
            "<i class='flaticon-left-arrow'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
    });

    // Headphone Design Slides
    $('.headphone-design-slides').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        autoplay: true,
        items: 1,
        navText: [
            "<i class='flaticon-left-arrow'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
    });

	// WOW JS
	$(window).on ('load', function (){
        if ($(".wow").length) {
            let wow = new WOW({
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       20,          // distance to the element when triggering the animation (default is 0)
            mobile:       true,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
          });
          wow.init();
        }
        let page = window.location.href;
        if (page.indexOf("#about")>0)
        {
            $('html, body').stop().animate({
                scrollTop: $("#about").offset().top - 45
            }, 300);
        }
        if (page.indexOf("#dealer")>0)
        {
            $('html, body').stop().animate({
                scrollTop: $("#dealer").offset().top - 45
            }, 300);
        }
    });

    // Go to Top
    $(function(){
        // Scroll Event
        $(window).on('scroll', function(){
            let scrolled = $(window).scrollTop();
            if (scrolled > 600) $('.go-top').addClass('active');
            if (scrolled < 600) $('.go-top').removeClass('active');
        });
        // Click Event
        $('.go-top').on('click', function() {
            $("html, body").animate({ scrollTop: "0" },  500);
        });
    });

}(jQuery));
